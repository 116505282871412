<template>
  <div>
<!--    <div class="top-nav-container">-->
<!--      <div class="logo">-->
<!--        <div>-->
<!--          <img src="../assets/img/nav/nav_logo.png"/>-->
<!--          <span>ChaoAI</span>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="nav-right">-->
<!--        <div class="but" @click="showLang = !showLang">-->
<!--          {{ langName }}-->
<!--        </div>-->
<!--        <div v-show="showLang" class="lang-cont">-->
<!--          <div class="lang-item" v-for="(item,index) in  langArr" :key="index"-->
<!--               @click="selectLanguage(item.key)">-->
<!--            {{ item.value }}-->
<!--          </div>-->
          <!--<div class="lang-item">-->
          <!--english-->
          <!--</div>-->
          <!--<div class="lang-item">-->
          <!--中文-->
          <!--</div>-->
<!--        </div>-->
<!--        <div class="nav_notice">-->
<!--          <div @click="toPage('/notice')">-->
<!--            <img src="../assets/img/nav/nav_icon_notice.png">-->
<!--          </div>-->
<!--&lt;!&ndash;          <div class="red_dot"></div>&ndash;&gt;-->
<!--        </div>-->
<!--        <div class="popup-but" @click="showPopup()"></div>-->
<!--      </div>-->
<!--    </div>-->
    <div v-if="showHeight" class="top-nav-height"></div>

    <van-popup v-model="show" position="right" :style="{ width: '70%',height:'100%'}">
      <div class="popup-cont">
        <div class="user_address">
          <div class="w_add">{{ $t('bring2.nav.walletAddress') }}</div>
          <div class="add" v-if="token"> {{ walletAdd | addFilter }}</div>
          <div class="add" v-else>--</div>

          <!--                    <img src="../../src/assets/img/nav/menu_address_bg.png">-->
          <img @click="logOut" class="log-out" src="../../src/assets/img/nav/menu_btn_log out.png">
        </div>
        <div class="nav-list">
          <div class="nav-list-50">
            <div class="nav-item nav-item-border" @click="toPage('/')">
                            <span>
                               {{ $t('bring2.nav.home') }}
                            </span>
              <img class="menu_pic_s" src="../assets/img/nav/menu_pic_s.png">
            </div>
            <div class="nav-item nav-item-long nav-item-border" @click="toPage('/algorithm')">
                            <span>
                                {{ $t('bring2.nav.coin') }}
                            </span>
              <img class="menu_pic_m" src="../assets/img/nav/menu_pic_m.png">
            </div>
            <div class="nav-item nav-item-border" @click="toPage('/team')">
                            <span>
                                {{ $t('bring2.nav.community') }}
                            </span>
              <img class="menu_pic_s" src="../assets/img/nav/menu_pic_s.png">
            </div>
            <div class="nav-item nav-item-border" @click="toPage('/property')">
                            <span>
                                 {{ $t('bring2.nav.property') }}
                            </span>
              <img class="menu_pic_s" src="../assets/img/nav/menu_pic_s.png">
            </div>
            <div class="nav-item nav-item-long nav-item-border" @click="toPage('/gpt')">
              <span>{{ $t('bring2.gpt') }}</span>
              <img class="menu_pic_m" src="../assets/img/nav/menu_pic_m.png">
            </div>
            <div class="nav-item" @click="stayTuned">
                            <span>
                                {{ $t('bring2.nav.website') }}
                            </span>
              <img class="menu_pic_s" src="../assets/img/nav/menu_pic_s.png">
            </div>
          </div>
          <div class="nav-list-50">
            <div class="nav-item nav-item-long nav-item-border" @click="toPage('/mining')">
              <!--            <div class="nav-item nav-item-long "  @click="stayTuned">-->
              <span> {{ $t('bring2.nav.destroyMining') }}</span>
              <img class="menu_pic_m" src="../assets/img/nav/menu_pic_m.png">
            </div>
            <div class="nav-item nav-item-long nav-item-border" @click="toPage('/power')">
              <!--            <div class="nav-item nav-item-long nav-item-border"  @click="stayTuned">-->
              <span>{{ $t('bring2.nav.power') }}</span>
              <img class="menu_pic_m" src="../assets/img/nav/menu_pic_m.png">
            </div>
            <div class="nav-item nav-item-border" @click="toPage('/convert')">
                            <span>
                                {{ $t('bring2.nav.exchange') }}
                            </span>
              <img class="menu_pic_s" src="../assets/img/nav/menu_pic_s.png">
            </div>
            <div class="nav-item nav-item-border" @click="toPage('/invite')">
                            <span>
                                {{ $t('bring2.nav.invite') }}
                            </span>
              <img class="menu_pic_s" src="../assets/img/nav/menu_pic_s.png">
            </div>
            <div class="nav-item nav-item-border" @click="toPage('/knowledge')">
              <!--            <div class="nav-item"  @click="stayTuned">-->
              <span>
                                 {{ $t('bring2.nav.knowledge') }}
                            </span>
              <img class="menu_pic_s" src="../assets/img/nav/menu_pic_s.png">
            </div>
            <div class="nav-item" @click="stayTuned">
                            <span>
                                {{ $t('bring2.nav.whitePaper') }}
                            </span>
              <img class="menu_pic_s" src="../assets/img/nav/menu_pic_s.png">
            </div>
          </div>
        </div>

        <div class="sign-in" v-if="questionBank && questionBank.seniority">
          <div class="menu_icon_sign_in">
            <img src="../assets/img/nav/menu_icon_sign_in.png">
          </div>
          <div class="sign-in-info">
            <p @click="showSignInPopup">
              <span v-if="questionBank.already"> {{ $t('bring2.nav.signedIn') }}</span>
              <span v-else>{{ $t('bring2.nav.signIn') }}</span>
<!--              <span class="sign-in-time">{{ questionBank.sign_days }}/{{ questionBank.days }}</span>-->
            </p>
            <p class="received">
              {{ $t('bring2.nav.received') }}{{ questionBank.receive_num }}BSDT
            </p>
          </div>
        </div>

        <div class="menu_prompt" v-if="questionBank && questionBank.seniority">
          <img class="menu_icon_prompt" src="../assets/img/nav/menu_icon_prompt.png">
          <p>{{ $t('bring2.nav.signInText') }}</p>
        </div>
      </div>
    </van-popup>


    <div class="sign_in_popup" v-if="signInPopup">
      <div class="sign_in_cont">
        <div class="top_bg">
          <img class="class_bg" src="../assets/img/knowledge/class_bg.png">
          <div class="btn_close" @click="signInPopup=false">
            <img src="../assets/img/knowledge/class_btn_close.png">
          </div>
          <div class="info">
            <div class="title">{{ $t('bring2.nav.plan') }}</div>
            <div class="subtitle">{{ $t('bring2.nav.prompt') }}</div>
          </div>
        </div>
        <div class="sign_in_form">
          <div class="result" v-if="solution == true">{{ $t('bring2.nav.correctAnswer') }}</div>
          <div class="result" v-if="solution == false">{{ $t('bring2.nav.wrongAnswer') }}</div>

          <div class="topic">{{ answerItem.content }}</div>
          <div class="answer_cont" :class="{'answer_cont_center': solution != null}">
            <div v-for="(item,index) in answerItem.answer" :key="index">
              <div v-if="solution != null">
                <div v-if="answer == index " class="answer_item"
                     :class="{'active': answer == index}"
                     @click="selectAnswer(index)">
                  {{ item }}
                  <img v-if="solution" class="result_icon"
                       src="../assets/img/knowledge/class_icon_success.png"/>

                  <img v-else class="result_icon"
                       src="../assets/img/knowledge/class_icon_error.png"/>
                </div>
              </div>
              <div v-else>
                <div class="answer_item"
                     :class="{'active': answer == index}"
                     @click="selectAnswer(index)">
                  {{ item }}
                </div>
              </div>

            </div>
            <!--            <div>-->
            <!--              <div class="answer_item">-->
            <!--                B：答案答案-->
            <!--                <img class="result_icon" src="../assets/img/knowledge/class_icon_error.png"/>-->
            <!--              </div>-->
            <!--            </div>-->

          </div>

          <div style="text-align: center" v-if="solution == null">
            <div class="select_but" @click="okAnswer">{{ $t('bring2.nav.select') }}</div>
          </div>


          <div v-if="solution == false" style="text-align: center">
            <router-link to="/knowledge">
              <div class="select_but">
                <span>{{ $t('bring2.nav.toLearn') }}</span>
              </div>
            </router-link>
            <div style="font-size: 0.88rem;color: #8F92A1;padding-top: 0.94rem">{{ $t('bring2.nav.tomorrow') }}</div>
          </div>

          <div style="text-align: center" v-if="solution == true">
            <div class="select_but">
              <img class="icon" src="../assets/img/knowledge/home_pic_logo.png">
              <span>{{ $t('bring2.nav.obtain') }}BSDT*1</span>
            </div>
            <div style="font-size: 0.88rem;color: #8F92A1;padding-top: 0.94rem">{{ $t('bring2.nav.tomorrow') }}</div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Web3 from 'web3'
import {ethers} from 'ethers';

import {
  getQuestionBank as getQuestionBankAPI,
  postQuestionBank as postQuestionBankAPI,

} from '@/api/index'
import {postQuestionBank} from "../api";
import {getToken} from '@/utils/auth'

export default {
  name: "top-nav",
  data() {
    return {
      show: false,
      showLang: false,
      walletAdd: this.$store.getters.walletAdd,
      signInPopup: false,

      langArr: [
        {
          'key': 'en',
          'value': 'English'
        },
        {
          'key': 'ja',
          'value': '日本語'
        },
        {
          'key': 'ko',
          'value': '한국어'
        },
        {
          'key': 'zh_CN',
          'value': '简体中文'
        },
        {
          'key': 'zh_TW',
          'value': '繁體中文'
        }
      ],
      lang: localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en',
      langName: '',
      that: this,
      questionBank: null,
      answerItem: null,
      answer: -1,
      solution: null,
      token: getToken(),
      to_url: '',
      loginForm: {
        address: '',
        msg: '',
        sign: '',
        invite_code: ''
      },
    }
  },
  props: {
    showHeight: {
      type: Boolean,
      default: true,
    },
  },
  filters: {
    addFilter(data) {
      if (data.length > 10) {
        return data.substr(0, 9) + '...' + data.substr(-4);
      } else {
        return '--'
      }

    }
  },
  watch: {
    '$store.getters.walletAdd'(newVal, oldVal) {
      // console.log(newVal);
      this.walletAdd = newVal
    },
  },
  created() {
    for (let item of this.langArr) {
      if (this.lang == item.key) {
        this.langName = item.value
        return
      }
    }
    this.langName = 'English'
  },
  methods: {
    stayTuned() {
      this.$toast(this.$t("bring2.stayTuned"));
    },
    selectAnswer(i) {
      this.answer = i
    },

    okAnswer() {
      if (this.answer == -1) {
        return;
      }

      if (this.answerItem.solution == this.answer) {
        this.solution = true
        this.postQuestionBank(0)
      } else {
        this.solution = false
        this.postQuestionBank(1)
      }
    },
    postQuestionBank(type) {
      postQuestionBankAPI({'type': type}).then(({data}) => {
        console.log(data);
        if (data.code !== 200) {
          this.$toast(data.message);
        }
      })
    },
    showSignInPopup() {
      if (this.questionBank.already) {
        return
      } else {
        this.answerItem = this.questionBank.question_list[0]
        this.answer = -1
        this.solution = null

        this.signInPopup = true
        this.show = false
      }
      //
    },
    toPage(path) {

      if (this.token || path == '/') {
        this.$router.push({
          path: path
        })
      } else {
        this.to_url = path
        this.sign_login()
      }
      // this.$router.push({
      //   path: path
      // })
    },

    async sign_login() {
      let that = this;
      const web3 = new Web3(window.ethereum);
      window.ethereum.enable();

      const accounts = await web3.eth.getAccounts();
      const address = accounts[0];

      var timestamp = new Date().getTime() + ''
      //
      const messageHash = web3.utils.sha3(timestamp);
      const message = 'Welcome to Bring'

      const signature = web3.eth.personal.sign(message, address).then(function (receipte) {
        console.log(receipte);

        // console.log(messageHash);
        let r = receipte.slice(0, 66)
        let s = '0x' + receipte.slice(66, 130)
        let v = '0x' + receipte.slice(130, 132)

        that.login({
          'address': address,
          'sign': receipte,
          'msg': message,
        })
      });
    },
    login(data) {
      let that = this;
      this.loginForm.address = data.address
      this.loginForm.msg = data.msg;
      this.loginForm.sign = data.sign;
      let invite_code = sessionStorage.getItem('invite_code')
      if (invite_code) {
        this.loginForm.invite_code = invite_code;
      }

      this.$store.dispatch('app/setWalletAdd', data.address)

      this.$store.dispatch('app/login', this.loginForm)
          .then((res) => {
            console.log(res);
            if (res.code == 200) {
              that.token = res.data.token
              that.$router.push({path: that.to_url});
            } else {
              that.$toast(res.message);
            }

          })
          .catch(() => {

          })
    },
    logOut() {
      window.ethereum.isConnected()
      this.$store.dispatch('app/logOut')
    },
    showPopup() {
      this.show = true;
      this.getQuestionBank()

    },
    selectLanguage(lang) {
      this.showLang = false
      localStorage.setItem('lang', lang);
      window.location.reload()
    },
    getQuestionBank() {
      let that = this
      getQuestionBankAPI().then(({data}) => {
        console.log(data);
        if (data.code === 200) {
          that.questionBank = data.data

        }
      })
    },

  },


}
</script>

<style scoped lang="less">
.top-nav-height {
  height: 3.8rem;
}

.top-nav-container {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  padding: 1.13rem 1.4rem;
  box-sizing: border-box;
  z-index: 99;

  .logo {
    position: relative;
    top: 0.2rem;
    float: left;
    width: 7.71rem;

    img {
      width: 100%;
    }
  }

  .nav-right {
    position: relative;
    float: right;
    text-align: right;

    .but {
      float: left;
      font-size: 0.75rem;
      text-align: center;
      height: 1.5rem;
      line-height: 1.5rem;
      min-width: 2.65rem;
      padding: 0 0.6rem;
      border: 1px solid #5D6671;
      border-radius: 0.857rem;
      background-repeat: repeat;
      background-size: 100% 100%;
    }

    .popup-but {
      position: relative;
      top: 0.28rem;
      float: right;
      margin-left: 1.5rem;
      display: inline-block;
      width: 1.25rem;
      height: 1.13rem;
      background-image: url("../assets/img/nav/nav_icon_menu.png");
      background-repeat: repeat;
      background-size: 100% 100%;

    }

    .lang-cont {
      position: relative;
      top: 2rem;
      padding-top: 0.5rem;
      padding-bottom: 0.2rem;
      position: absolute;
      width: 4.81rem;
      text-align: center;
      z-index: 9;
      font-weight: 400;
      font-size: 0.66rem;
      background-color: rgba(0, 0, 0, 0.8);

      .lang-item {
        padding: 0.33rem 0;
      }
    }
  }
}

.popup-cont {
  padding: 1.6rem 1.2rem 0 1.2rem;

  .user_address {
    position: relative;
    height: 4.1rem;
    background: url("../assets/img/nav/menu_address_bg.png") no-repeat;
    background-size: 100%;
    padding: 0.8rem 0 0 1.2rem;
    border-radius: 0.5rem;
    overflow: hidden;

    .w_add {
      font-size: 0.75rem;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.8);
    }

    .add {
      line-height: 1.8rem;
    }

    img {
      width: 100%;
    }

    .log-out {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      right: 0.86rem;
      width: 2.29rem;
      height: 2.29rem;
    }
  }

  .logo-close {
    overflow: hidden;

    .logo-gray {
      float: left;
      width: 4.44rem;
    }

    .close-icon {
      position: relative;
      float: right;
      top: 0.3rem;
      width: 0.88rem;
    }
  }

  .nav-list {
    overflow: hidden;

    .nav-list-50 {
      float: left;
      width: 50%;
      box-sizing: border-box;
    }

    .nav-list-50:nth-child(odd) {
      padding-right: 1.5%;
    }

    .nav-list-50:nth-child(even) {
      padding-left: 1.5%;
    }

    .nav-item {
      position: relative;
      display: flex;
      margin-top: 0.65rem;
      height: 3.86rem;
      flex-direction: column;
      justify-content: center;
      align-self: center;
      text-align: center;
      background: #161A23;
      border-radius: 0.57rem;
      font-size: 1rem;
      color: #CBCBD5;

      span {
        line-height: 1.2rem;
      }
    }

    .nav-item-border {
      border: 1px solid #F68D31;
    }

    .nav-item-long {
      height: 8.57rem;
    }

    .menu_pic_s {
      position: absolute;
      top: 0;
      right: 0;
      height: 3.86rem;
    }

    .menu_pic_m {
      position: absolute;
      top: 0;
      right: 0;
      width: 4.5rem;
    }
  }

  .sign-in {
    position: relative;
    padding: 0.71rem 0 0.71rem 0.5rem;
    box-sizing: border-box;
    margin-top: 1rem;
    height: 4.29rem;
    background: linear-gradient(117deg, #F68D31 0%, #F75D24 100%);
    border-radius: 0.57rem;

    .menu_icon_sign_in {
      display: inline-block;
      height: 3.5rem;

      img {
        width: 3.06rem;
      }
    }

    .sign-in-info {
      padding-left: 0.5rem;
      display: inline-block;
      line-height: 1.45rem;

      .sign-in-time {
        position: absolute;
        right: 1rem;
      }
    }

    .received {
      font-size: 0.9rem;
      color: rgba(255, 255, 255, 0.5);
    }
  }

  .menu_prompt {
    margin-top: 1rem;
    padding-bottom: 1rem;
    overflow: hidden;

    .menu_icon_prompt {
      width: 1.14rem;
      height: 1.14rem;
    }

    p {
      float: right;
      width: 88%;
      font-size: 0.8rem;
      font-weight: 400;
      color: #8F92A1;
    }

  }
}

.sign_in_popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 99;

  .sign_in_cont {
    position: relative;
    padding-top: 6rem;

    .top_bg {
      position: relative;

      .class_bg {
        width: 55%;
      }

      .btn_close {
        position: absolute;
        top: 4rem;
        right: 2.31rem;
        width: 1.17rem;
        height: 1.17rem;
        z-index: 3;

        img {
          width: 100%;
        }
      }

      .info {
        display: inline-block;
        width: 45%;
        position: relative;
        bottom: 6rem;

        .title {
          font-size: 1.3rem;
          font-weight: bold;
        }

        .subtitle {
          font-size: 0.88rem;
          font-weight: 400;
          line-height: 1.56rem;
          color: #8F92A1;
        }
      }
    }

    .sign_in_form {
      position: relative;
      top: -5rem;
      margin: 0 1.25rem;
      background: #282C35;
      border-radius: 1.25rem;
      padding: 2.38rem 2rem;

      .result {
        font-size: 1.25rem;
        font-weight: 600;
        line-height: 1.75rem;
        color: #FFFFFF;
        text-align: center;
        padding-bottom: 1.38rem;
      }

      .topic {
        font-size: 1.1rem;
        font-weight: 400;
        color: #8F92A1;
      }

      .answer_cont_center {
        text-align: center;
      }

      .answer_cont {
        padding-top: 2rem;

        > div {
          display: inline-block;
          margin-right: 1rem;
          margin-bottom: 1rem;
        }

        .answer_item {
          position: relative;
          color: #8F92A1;
          display: inline-block;
          padding: 0.75rem 1.5rem;
          border: 1px solid #4F5562;
          border-radius: 1.44rem;
        }

        .result_icon {
          position: absolute;
          width: 1.88rem;
          height: 1.88rem;
          right: -0.44rem;
          bottom: -0.44rem;
        }

        .active {
          border-color: #F68D31;
          color: #F68D31;
        }
      }

      .select_but {
        display: inline-block;
        min-width: 13.38rem;
        text-align: center;
        line-height: 4.19rem;
        padding: 0 0.5rem;
        background: #F68D31;
        border-radius: 2.13rem;
        margin: auto;
        margin-top: 3.25rem;

        .icon {
          position: relative;
          top: 0.5rem;
          width: 1.88rem;
          height: 1.88rem;
          padding-right: 0.75rem;
        }

        span {
          color: #fff;
        }
      }
    }


  }
}
.nav_notice{
  position: relative;
  margin-left: 1.3rem;
  display: inline-block;
  width: 1.65rem;
  height: 1.65rem;
  img{
    width: 100%;
  }

  .red_dot{
    position: absolute;
    right: 0;
    top: 0;
    width: 0.5rem;
    height: 0.5rem;
    background: #FF3E3E;
    border-radius: 50%;
  }
}
</style>
