<template>
  <div style="max-height: 100vh;overflow-y: auto" ref="scrollWrapper">
    <top-nav></top-nav>
    <div class="content">
      <div class="new_dialogue" v-if="messageList.length < 1">
        <img class="gpt_logo" src="../../../assets/img/gpt/gpt_logo.png">
        <div class="promotion_msg">
          {{ $t('bring2.gptPage.welcomeTo') }}
        </div>
      </div>

      <div class="msg_list">
        <van-pull-refresh v-model="isLoading"
                          :loading-text="$t('bring2.loading')"
                          pulling-text=" "
                          loosing-text=" ">
          <div v-for="(item,index) in messageList" :key="index">
            <div class="this_msg">
              <div class="avatar">
                <img class="avatar_img" src="../../../assets/img/gpt/logo1.png">
              </div>
              <div class="msg_cont">
                <div class="msg_text">{{ item.question }}</div>
                <div class="copy_icon" @click="copyData(item.question)">
                  <img src="../../../assets/img/gpt/copy_icon.png">
                </div>
              </div>
            </div>

            <div class="other_msg">
              <div class="avatar">
                <img class="avatar_img" src="../../../assets/img/gpt/gpt_logo.png">
              </div>
              <div class="msg_cont">
                <div class="msg_text" v-if="item.answer">
                  {{ item.answer }}
                </div>
                <div class="loading_cont" v-else>
                  <div class="loading"></div>
                </div>
                <div class="copy_icon" v-if="item.answer" @click="copyData(item.answer)">
                  <img src="../../../assets/img/gpt/copy_icon.png">
                </div>
              </div>
            </div>
          </div>
        </van-pull-refresh>

      </div>

    </div>
    <div :style="{height: offsetHeight >0 ? offsetHeight+'px' : '40vh' }"></div>
    <div class="input_cont" id="input_cont">
      <van-field
          class="field"
          v-model="message"
          type="textarea"
          maxlength="150"
          :disabled="fieldDisabled"
          :readonly="outputState"
          :placeholder="$t('bring2.gptPage.pleaseQuestion')"
          show-word-limit
          @keyup.enter.native="keyupClick"
      />

    </div>
  </div>
</template>

<script>
import TopNav from '@/components/topNav.vue'
import {
  getUserGptQuestionByUid as getUserGptQuestionByUidApi,
  getAskNumber as getAskNumberApi
} from '@/api/index'
import {Notify} from "vant";

export default {
  name: "index",
  data() {
    return {
      message: '',
      offsetHeight: 0,
      messageList: [],
      page: 1,
      isLoading: false,
      fieldDisabled: false,
      outputState: false,
      socket: null,
      num: 0
    }
  },
  components: {TopNav},
  mounted() {
    this.offsetHeight = document.getElementById('input_cont').offsetHeight
  },
  methods: {
    async openSocket(msg) {
      let that = this;
      let wsUrl = 'wss://chao-ai.kiman.top/wss';
      let socket = new WebSocket(wsUrl);
      socket.onmessage = this.webSocketOnMessage;
      socket.onopen = function (event) {
        // console.log(socket);
        if (socket) {
          let message = {
            "temperature": 0.1,
            "stream": true,
            "messages": [
              {
                "role": "user",
                "content": msg
              }
            ],
            "model": "gpt-3.5-turbo"
          };

          that.socket = socket
          that.socket.send(JSON.stringify(message))
          console.log('WebSocket Success');
        }

      };
    },
    webSocketOnMessage(e) {
      let that = this;
      const arr = e.data.split("\n");
      arr.forEach((chunk) => {
        if (chunk === "") {
          return;
        }
        chunk = chunk.substring(6).trim();
        if (chunk === "") {
          return;
        }
        if (chunk === "[DONE]") {
          console.log('[DONE]', chunk);
          that.fieldDisabled = false
          that.outputState = false
          that.socket.close()
          return;
        }
        try {
          const data = JSON.parse(chunk);

          let length = that.messageList.length - 1;
          let content = data.choices[0].delta.content;

          if (content) {
            that.messageList[length].answer += content;
            that.bottomScrollClick();
          } else {
            console.log('Error Message', data);
          }
        } catch (e) {
          console.log('error', e);
        }
      })
    },

    keyupClick() {
      getAskNumberApi().then(({ data }) => {
        if (data.code === 200) {
          let that = this;
          if (this.message) {
            if (that.outputState) {
              return;
            }
            this.message = this.message.replace(/(\r\n|\n|\r)/gm, "");

            let message = that.message;
            that.message = '';

            that.messageList.push({
              question: message,
              answer: "",
            })

            that.fieldDisabled = true
            that.bottomScrollClick()
            that.openSocket(message);
          }
        } else if(data.code === 422) {
          this.$toast("次数已用完，请明天再来~");
        }
      })

    },
    copyData(text) {
      let that = this;
      this.$copyText(text).then(
          function (e) {
            Notify(
                {
                  type: 'success',
                  message: that.$t('referral.copy_succee'),
                  duration: 1500,
                }
            );
          }
      );
    },
    bottomScrollClick() {
      this.$nextTick(() => {
        let scrollEl = this.$refs.scrollWrapper;
        scrollEl.scrollTo({top: scrollEl.scrollHeight, behavior: 'smooth'});
      });
    },
  },

}
</script>

<style scoped lang="less">
.content {
  margin: 0 4vw;

  .new_dialogue {
    padding: 2.5rem 6vw 0 6vw;
    margin-bottom: 2rem;

    .gpt_logo {
      width: 18vw;
      padding-right: 2vw;
    }

    .promotion_msg {
      float: right;
      width: 60vw;
      color: #F68D31;
      padding: 1rem 1.6rem 1rem 1rem;
      box-sizing: border-box;
      font-size: 0.88rem;
      background: rgba(40, 44, 53, 0.39);
      border-radius: 0.63rem 12.5rem 12.5rem 12.5rem;
    }
  }


  .msg_list {
    overflow: hidden;

    .this_msg {
      text-align: right;
      overflow: hidden;
      margin-bottom: 1rem;

      .msg_cont {
        position: relative;
        float: right;
        color: #8F92A1;
        font-size: 0.88rem;

        .msg_text {
          position: relative;
          max-width: 58vw;
          margin-right: 4vw;
          padding: 0.94rem 4vw;
          background-color: #282C35;
          border-radius: 1.25rem;
        }

        .copy_icon {
          position: absolute;
          left: -6vw;
          bottom: 0;
          width: 4vw;

          img {
            width: 100%;
          }
        }

      }

      .avatar {
        float: right;
        background-color: #282C35;
        padding: 2vw;
        border-radius: 50%;
        width: 10vw;
        height: 10vw;

        .avatar_img {
          width: 100%;
        }
      }

    }

    .other_msg {
      text-align: left;
      overflow: hidden;
      margin-bottom: 1rem;

      .msg_cont {
        position: relative;
        float: left;
        color: #8F92A1;
        font-size: 0.88rem;

        .msg_text {
          max-width: 58vw;
          margin-left: 4vw;
          padding: 0.94rem 4vw;
          background-color: #282C35;
          border-radius: 1.25rem;
        }

        .copy_icon {
          position: absolute;
          right: -6vw;
          bottom: 0;
          width: 4vw;

          img {
            width: 100%;
          }
        }

      }

      .avatar {
        float: left;
        background-color: #282C35;
        padding: 2vw;
        border-radius: 50%;
        width: 10vw;
        height: 10vw;

        .avatar_img {
          width: 100%;
        }
      }

    }

  }


}

.input_cont {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 1.6rem 4vw;
  box-sizing: border-box;
  background-color: #161A23;
  border-top: 1px solid #2C323E;

  :deep(.van-cell__value) {
    background: #282C35;
  }

  :deep(.van-cell) {
    padding: 0.8rem 1.3rem;
    border-radius: 0.5rem;
    background: #282C35;
  }

  :deep(.van-field__control) {
    color: #CBCBD5 !important;
  }

  :deep(.van-field__word-limit) {
    color: #F68D31 !important;
    font-size: 0.88rem;
  }
}

.loading_cont {
  position: relative;
  height: 1.4rem;
  margin-left: 4vw;
  width: 20vw;
  padding: 0.94rem 4vw;
  background-color: #282C35;
  border-radius: 1.25rem;
}

.loading:before,
.loading:after,
.loading {
  border-radius: 50%;
  width: 0.5rem;
  height: 0.5rem;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}

.loading {
  left: 2rem;
  top: -2rem;
  position: relative;
  text-indent: -9999em;
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.loading:before {
  left: -1.5rem;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.loading:after {
  left: 1.5rem;
}

.loading:before,
.loading:after {
  content: '';
  position: absolute;
  top: 0;
}

@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5rem 0 -1.3rem #8F92A1;
  }
  40% {
    box-shadow: 0 2.5rem 0 0 #8F92A1;
  }
}

@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5rem 0 -1.3rem #8F92A1;
  }
  40% {
    box-shadow: 0 2.5rem 0 0 #8F92A1;
  }
}
</style>
